<script setup lang="ts">
  defineProps<{
    columns: 'one' | 'two';
  }>();

  defineSlots<{
    default: () => void;
  }>();

  const slots = useSlots();

  const isDefaultSlotNotEmpty = computed(() => {
    return slots.default && slots.default()[0].children?.length;
  });
</script>

<template>
  <div
    v-if="isDefaultSlotNotEmpty"
    :class="{
      'grid': true,
      [`grid--${columns}`]: true,
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--mp-space-50);
    width: 100%;

    &--two {
      @include screen-lg {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
</style>
